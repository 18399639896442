import React from "react";
import { graphql } from "gatsby";
import { Layout } from "../layouts/layout";
import { ListHeader } from "../components/header";
import { PostList } from "../components/post-list";

const ListPage = (props) => {
    let { data } = props;

    return (
        <>
            <ListHeader key="header" title="Strategy" />
            <PostList
                key="list"
                projects={data.allMarkdownRemark.edges}
            ></PostList>
        </>
    );
};

const Index = (props) => {
    return (
        <Layout>
            <ListPage id={"planning"} path="/" {...props} />
        </Layout>
    );
};

export default Index;

export const query = graphql`
    query {
        allMarkdownRemark(
            filter: {
                frontmatter: {
                    published: { ne: false }
                    category: { in: "Planning" }
                }
            }
            sort: { fields: [frontmatter___pub_date], order: DESC }
        ) {
            totalCount
            edges {
                node {
                    id
                    frontmatter {
                        title
                        date
                        location
                        published
                        private
                        category
                        featuredImage {
                            childImageSharp {
                                fluid(
                                    cropFocus: CENTER
                                    toFormat: WEBP
                                    srcSetBreakpoints: [264, 300, 466]
                                    maxHeight: 500
                                    maxWidth: 500
                                    sizes: "[264, 300, 466, 500, 1024]"
                                    quality: 80
                                    webpQuality: 80
                                ) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    fields {
                        slug
                    }
                    excerpt
                    htmlAst
                }
            }
        }
    }
`;
